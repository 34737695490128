/*
	App routes files
*/
import React, { Suspense, lazy } from 'react';
import { 	Route,
			Switch, 
			Redirect, 
			BrowserRouter as Router } from 'react-router-dom'

// pages

// lazy load components
const Yoga = React.lazy(() => import('./pages/Yoga/lp'));
const OpenningPage = React.lazy(() => import('./pages/OpenningPage'));
const ThinkPad = React.lazy(() => import('./pages/ThinkPad/lp'));
const ThankYou = React.lazy(() => import('./pages/Yoga/ThankYou'));
const ThankYouThinkPad = React.lazy(() => import('./pages/ThinkPad/ThankYouThinkPad'));
const Workstations = React.lazy(() => import('./pages/Workstations/lp'));
const ThankYouWorkStations = React.lazy(() => import('./pages/Workstations/ThankYouWorkStations'));



// Posts
// import Posts from '../Posts/Posts/Posts';

/*
 * Defing client route
 * 
*/
export const Routes = () => {
	
	return(
		<Router>
		  	<Suspense fallback={<div>Loading...</div>}>
				<Switch>
				<Route path="/thankYouWorkStations" component={ThankYouWorkStations} />
				    <Route path="/workStations" component={Workstations} />
				    <Route path="/workStationsV1" component={Workstations} />
				    <Route path="/thankYouThinkPad" component={ThankYouThinkPad} />
				    <Route path="/thankYou" component={ThankYou} />
					<Route path="/thinkPad" component={ThinkPad} />
					<Route path="/yoga" component={Yoga} />
					<Route path="/"  component={Yoga} />
				</Switch>
		 	</Suspense>
		</Router>

	)
}
