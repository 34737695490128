import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'


import App from './App';
import './fonts/fonts.css';
import * as serviceWorker from './serviceWorker';
import * as config from './config';



const basename = "/Debby/2020/11/";


ReactDOM.render(
	
		<BrowserRouter basename={basename} forceRefresh={true}>
			<App />
		</BrowserRouter>
	, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();




